import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
let token = localStorage.getItem("token")
console.log(token);
export default new Vuex.Store({
  state: {
    token:token?token: ""
  },
  getters: {
  },
  mutations: {
    saveToken(state, token) {
      state.token = token
  },
  // changeNavList(state,list){
  //     state.navlist = list
  // }
  },
  actions: {
  },
  modules: {
  }
})
