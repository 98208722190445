import Vue from 'vue'
import VueI18n from 'vue-i18n'
import EN from './en'
import ZH from './zh'

Vue.use(VueI18n)
const messages = {
  cn: {
    ...ZH
  },
  en: {
    ...EN
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem('internationalization') || 'en', // 设置当前语言类型
  messages
})

export default i18n
