/**
 * 限制输入框只能输入数字，且只能保留两位小数
 */
const inputNumbersWithOnlyTwoDecimalPlaces = {
  bind(el, binding) {
    el.addEventListener('input', function (event) {
      let value = event.target.value
      // 只保留数字和小数点，确保只有一个小数点
      if (binding.value && binding.value != 0) {
        // 保留小数后两位
        value = value.replace(/[^\d.]+/g, '')
        const decimalCount = (value.match(/\./g) || []).length
        if (decimalCount > 1) {
          const firstDecimalIndex = value.indexOf('.')
          value = value.slice(0, firstDecimalIndex + 1)
          value += value.slice(firstDecimalIndex + 1).replace(/\./g, '')
        }

        // 截取小数点后两位数字
        const decimalIndex = value.indexOf('.')
        if (decimalIndex !== -1) {
          value = value.slice(0, decimalIndex + 3) // 限制小数点后两位数字
        }
      } else {
        // 整数
        value = value.replace(/[^0-9]+/g, '')
      }

      // 更新输入框的值
      if (event.target.value !== value) {
        event.target.value = value
        event.target.dispatchEvent(new Event('input')) // 触发输入事件，更新 v-model 绑定的值
      }
    })
  }
}

export default inputNumbersWithOnlyTwoDecimalPlaces
