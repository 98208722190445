import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    //验证码登录
    name: 'code-login',
    path: '/',
    component: () => import('../views/code-login.vue')
  },
  //登录页面
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  //创建账号
  {
    path: '/create-account',
    name: 'create-account',
    component: () => import('../views/create-account.vue')
  },
  //申请材料
  {
    path: '/application-material',
    name: 'application-material',
    component: () => import('../views/application-material.vue')
  },
  //入驻等待验证
  {
    path: '/await-verification',
    name: 'await-verification',
    component: () => import('../views/await-verification.vue')
  },
  //入驻开店成功
  {
    path: '/shop-succeed',
    name: 'shop-succeed',
    component: () => import('../views/shop-succeed.vue')
  },

  //主页
  {
    path: '/homea',
    name: 'homea',
    redirect: '/home-page',
    component: () => import('../views/home.vue'),
    //菜品分类
    children: [
      {
        name: 'Category',
        path: '/Category',
        component: () => import('../views/Category.vue')
      },
      {
        //菜品规格
        name: 'Attribute',
        path: '/Attribute',
        component: () => import('../views/Attribute.vue')
      },
      {
        //菜品列表
        name: 'Food',
        path: '/Food',
        component: () => import('../views/Food.vue')
      },
      {
        name: 'general-specification',
        path: '/general-specification',
        component: () => import('../views/general-specification/index.vue'),
        meta: { title: '通用规格' }
      },
      {
        name: 'action',
        path: '/action',
        component: () => import('../views/action/index.vue'),
        meta: { title: '动作配置' }
      },
      {
        //餐厅信息
        name: 'Store',
        path: '/Store',
        component: () => import('../views/Store.vue')
      },
      {
        //支付配置
        name: 'Payment',
        path: '/Payment',
        component: () => import('../views/Payment.vue')
      },
      {
        //服务员管理
        name: 'Account',
        path: '/Account',
        component: () => import('../views/Account.vue')
      },

      {
        //添加菜品分类页面
        name: 'management',
        path: '/management',
        component: () => import('../views/management.vue')
      },
      {
        //菜品详情
        name: 'Dishes',
        path: '/Dishes',
        component: () => import('../views/Dishes.vue')
      },
      {
        name: 'lianx',
        path: '/lianx',
        component: () => import('../views/lianx.vue')
      },
      ,
      {
        name: 'lianxb',
        path: '/lianxb',
        component: () => import('../views/lianxb.vue')
      },
      {
        name: 'gorkys',
        path: '/gorkys',
        component: () => import('../views/gorkys.vue')
      },
      {
        //订单列表
        name: 'order-management',
        path: '/order-management',
        component: () => import('../views/order-management.vue')
      },
      {
        // 会员订单管理
        name: 'vip-order-management',
        path: '/vip-order-management',
        component: () => import('../views/vip-order-management/index.vue')
      },
      {
        //账单对账
        name: 'billreconciliation',
        path: '/billreconciliation',
        component: () => import('../views/billreconciliation.vue')
      },
      {
        //账单明细
        name: 'billingdetails',
        path: '/billingdetails',
        component: () => import('../views/billingdetails.vue')
      },
      {
        //收入账户
        name: 'revenue-account',
        path: '/revenue-account',
        component: () => import('../views/revenue-account.vue')
      },
      {
        name: 'account-information',
        path: '/account-information',
        component: () => import('../views/account-information.vue')
      },
      {
        name: 'abouchement',
        path: '/abouchement',
        component: () => import('../views/abouchement.vue')
      },
      {
        name: 'extract',
        path: '/extract',
        component: () => import('../views/extract.vue')
      },
      {
        name: 'equipment-management',
        path: '/equipment-management',
        component: () => import('../views/equipment-management.vue')
      },
      {
        //充值
        name: 'promotion',
        path: '/promotion',
        component: () => import('../views/promotion.vue')
      },
      {
        //推广费充值详情
        name: 'promotion-particulars',
        path: '/promotion-particulars',
        component: () => import('../views/promotion-particulars.vue')
      },
      {
        //一键推广
        name: 'promotion-management',
        path: '/promotion-management',
        component: () => import('../views/promotion-management.vue')
      },
      {
        //推广账户
        name: 'promotion-account',
        path: '/promotion-account',
        component: () => import('../views/promotion-account.vue')
      },
      {
        //客户管理
        name: 'customer-management',
        path: '/customer-management',
        component: () => import('../views/customer-management.vue')
      },
      {
        // 会员管理
        name: 'vip-management',
        path: '/vip-management',
        component: () => import('../views/vip-management/index.vue')
      },
      {
        // 会员管理-批量异常新增
        name: 'abnormal-add',
        path: '/abnormal-add',
        component: () => import('../views/vip-management/abnormal-add.vue')
      },
      {
        //客户管理
        name: 'qr-management',
        path: '/qr-management',
        component: () => import('../views/qr-management.vue')
      },
      {
        //优惠券管理
        name: 'coupon-management',
        path: '/coupon-management',
        component: () => import('../views/coupon-management.vue')
      },
      {
        //新增优惠券
        name: 'add-coupons',
        path: '/add-coupons',
        component: () => import('../views/add-coupons.vue')
      },
      {
        //编辑优惠券
        name: 'compile-coupon',
        path: '/compile-coupon',
        component: () => import('../views/compile-coupon.vue')
      },
      {
        //首页数据
        name: 'home-page',
        path: '/home-page',
        component: () => import('../views/home-page.vue')
      },
      {
        //角色管理
        name: 'role-management',
        path: '/role-management',
        component: () => import('../views/role-management.vue')
      },
      {
        //员工管理
        name: 'staff-management',
        path: '/staff-management',
        component: () => import('../views/staff-management.vue')
      },
      {
        //员工审核管理
        name: 'Employee-audit',
        path: '/Employee-audit',
        component: () => import('../views/Employee-audit.vue')
      },
      {
        //资金管理
        name: 'Funds-management',
        path: '/Funds-management',
        component: () => import('../views/Funds-management.vue')
      },
      {
        //打卡记录
        name: 'Punching-card',
        path: '/Punching-card',
        component: () => import('../views/Punching-card.vue')
      },
      {
        //广告管理
        name: 'Advertising-management',
        path: '/Advertising-management',
        component: () => import('../views/Advertising-management.vue')
      },
      {
        //库存管理
        name: 'Inventory-management',
        path: '/Inventory-management',
        component: () => import('../views/Inventory-management.vue')
      },
      {
        //入库管理
        name: 'Stock-management',
        path: '/Stock-management',
        component: () => import('../views/Stock-management.vue')
      },
      {
        //出库管理
        name: 'Warehouse-management',
        path: '/Warehouse-management',
        component: () => import('../views/Warehouse-management.vue')
      },
      {
        //新建食材
        name: 'New-ingredient',
        path: '/New-ingredient',
        component: () => import('../views/New-ingredient.vue')
      },
      {
        //新建入库
        name: 'New-inbound',
        path: '/New-inbound',
        component: () => import('../views/New-inbound.vue')
      },
      {
        //数据分析
        name: 'data-analysis',
        path: '/data-analysis',
        component: () => import('../views/data-analysis.vue')
      },
      {
        // 会员基础配置
        name: 'vip-basic-configuration',
        path: '/vip-basic-configuration',
        component: () => import('../views/system-management/vip-basic-config.vue')
      }
    ]
  }

  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('../views/login.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
