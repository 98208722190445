import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
var internationalization = localStorage.getItem('internationalization')
import locale from 'element-ui/lib/locale/lang/en'
import localea from 'element-ui/lib/locale/lang/zh-CN'
import Directives from './directives/index'

import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts

//引入字体
import './assets/fonts/site.css'
import 'lib-flexible'
// import '@/assets/font_3638714_93so5ivm6um/iconfont'
import { videoPreUrl } from '@/utils/config.js'
import i18n from './lang/index'

// 引入拖拽排序插件
import VueDND from 'awe-dnd'

Vue.use(VueDND)
// 全局方法挂载
Vue.prototype.videoPreUrl = videoPreUrl

Vue.config.productionTip = false

import VueLazyload from 'vue-lazyload'

// 配置懒加载属性
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require('./assets/PlaceholderGrayLogo.png'), // 报错时候的图片
  loading: require('./assets/PlaceholderGrayLogo.png'), //加载时候的图片
  attempt: 1
})

// Vue.use(ElementUI, {a});
if (internationalization == 'en') {
  Vue.use(ElementUI, { locale })
} else {
  Vue.use(ElementUI, { localea })
}

// 注册自定义指令
Vue.use(Directives)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
