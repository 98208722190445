/**
 * 防抖指令
 * 使用防抖，防止按钮在短时间内多次点击，设置按钮在1秒内只能点击一次
 */
const debounce = {
  inserted: (el, binding) => {
    // 没有绑定函数抛出错误
    if (typeof binding.value !== 'function') {
      throw 'debounce callback not a function'
    }
    let timer
    el.addEventListener('click', () => {
      if (timer) clearTimeout(timer)

      timer = setTimeout(_ => {
        binding.value()
      }, 1000)
    })
  }
}

export default debounce
