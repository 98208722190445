import inputTwoDecimalPlaceNumber from './input-number'
import debounce from './debounce'
import elTableInfiniteScroll from 'el-table-infinite-scroll'

const directives = {
  inputNumber: inputTwoDecimalPlaceNumber,
  debounce,
  elTableInfiniteScroll
}

export default {
  install(Vue) {
    Object.keys(directives).forEach(key => {
      Vue.directive(key, directives[key])
    })
  }
}
